<template>
    <div>
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item label="省份">
                        <el-select style="width: 110px;" v-model="filters.provinceCode" placeholder="请选择省" filterable clearable>
                            <el-option
                                v-for="item in provinceArr"
                                :key="item.id"
                                :label="item.name"
                                :value="item.code"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="">
                        <el-form-item label="履约日期:" >
                        <el-date-picker @change="changePicker" v-model="pickerData" type="daterange" range-separator="至"
                            start-placeholder="开始" value-format="yyyy-MM-dd" end-placeholder="结束"
                                        style="width: 200px;">
                        </el-date-picker>
                    </el-form-item>
                    </el-form-item>
                    <el-form-item label="">
                        <el-select v-model="filters.conclusionStatus" placeholder="项目状态" clearable
                                   style="width: 110px;">
                            <el-option
                                v-for="item in modelTypeArr"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                  <el-form-item label="">
                    <el-select style="width: 150px;" v-model="filters.projectMatch" placeholder="收款匹配状态"
                               clearable>
                      <el-option
                          v-for="item in objectToMatch"
                          :key="item.id"
                          :label="item.name"
                          :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="">
                    <el-select style="width: 150px;" v-model="filters.invoiceMatch" placeholder="发票匹配状态"
                               clearable>
                      <el-option
                          v-for="item in billToMatch"
                          :key="item.id"
                          :label="item.name"
                          :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 列表 -->
        <div style="margin-bottom: 5px;">
            总图审面积：{{reviewArea}}（平方米）    项目总金额：{{reviewQuota}}（元）
        </div>
        <el-table
            :data="exportBoolean?exportList:tableData"
            id="exportTab"
            border
            style="width: 100%"
            v-loading="listLoading"
            ref="multipleTable"
            @current-change='currentChange'
            :highlight-current-row='true'
            :cell-style="rowClass"
        >
            <el-table-column type='index' label="序号" width='70' align="center" :index='indexMethod'></el-table-column>
            <!-- <el-table-column type="selection" width="45"></el-table-column> -->
            <el-table-column prop="projectNo" label="项目编号" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="projectName" label="项目名称" width='220' align="center" show-overflow-tooltip>
                <!-- <template slot-scope='scope'>
                    <el-button @click="viewsEntyTemplate(scope.row)" type="text">{{scope.row.projectName}}</el-button>
                </template> -->
            </el-table-column>
            <el-table-column prop="areaName" label="县（区）" width='90' align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="contractBn" label="关联合同编号" width='120' align="left" show-overflow-tooltip></el-table-column>
            <el-table-column prop="epName" label="项目联系人" width='90' align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="epPhone" label="联系方式" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="projectUnitPrice" label="合同单价（元）" width='90' align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="projectArea" label="图审面积（平方米）" width="100" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="projectTotalPrice" label="项目金额(元)" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="conclusionStatus" label="项目状态" width='90' align="center" >
                <!-- <template slot-scope='scope'>
                    <el-tag v-if="scope.row.AuditStatus == 1" type="success">已审结</el-tag>
                    <el-tag v-else type="danger">已冻结</el-tag>
                </template> -->
            </el-table-column>
            <el-table-column prop="conclusionDateTime" label="项目归档日期" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="verificationStatus" label="收款匹配状态" align="center" show-overflow-tooltip>
              <template slot-scope='scope'>
                <el-tag v-if="scope.row.verificationStatus === 0" type="danger">未匹配</el-tag>
                <el-tag v-if="scope.row.verificationStatus === 1" type="success">部分匹配</el-tag>
                <el-tag v-if="scope.row.verificationStatus === 2">全匹配</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="invoiceStatus" label="发票匹配状态" align="center" show-overflow-tooltip>
              <template slot-scope='scope'>
                <el-tag v-if="scope.row.invoiceStatus === 0" type="danger">未匹配</el-tag>
                <el-tag v-if="scope.row.invoiceStatus === 1" type="success">部分匹配</el-tag>
                <el-tag v-if="scope.row.invoiceStatus === 2">全匹配</el-tag>
              </template>
            </el-table-column>
        </el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.pageIndex"
                :page-sizes="pages.pageArr"
                :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="dataCount">
            </el-pagination>
        </el-col>
        <!-- 新建短信群发 -->
        <el-dialog
            title="新建短信群发"
            :visible.sync="applyForSendSMSDialog"
            v-model="applyForSendSMSDialog"
            :close-on-click-modal="false"
            width='50%'
        >
            <el-form :model="applyForSendSMSForm" :rules="applyForSendSMSRules" ref="applyForSendSMSRef" label-width="120px" style='min-width="800px"'>
                <el-form-item label="通知名称:" prop="name">
                    <el-input v-model='applyForSendSMSForm.name'></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="applyForSendSMSubmit" :loading="applyForSendSMLoading">申请发送</el-button>
                <el-button @click.native="applyForSendSMSDialog = false">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import { getAreaDictionaryList, getProjectConclusionReportList, getProjectConclusionSummary } from '@/api/api'
import FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
export default {
    components:{Toolbar},
    data() {
        return {
          dataCount:undefined,
            buttonList: [],
            needData: '',
            filters: {
                provinceCode: '',
                conclusionStatus: '',
                filingStartDate: undefined,
                filingEndDate: undefined,
                invoiceMatch:undefined,
                objectMatch:undefined,
            },
          //发票状态
          billToMatch: [
            { name: '未匹配', value: 0 },
            { name: '部分匹配', value: 1 },
            { name: '全部匹配', value: 2 }
          ],
          //项目状态
          objectToMatch: [
            { name: '未匹配', value: 0 },
            { name: '部分匹配', value: 1 },
            { name: '全部匹配', value: 2 }
          ],
            provinceArr: [],
            modelTypeArr: [
                {name: '已审结',value: 56},
                {name: '已冻结',value: 57},
            ],
            tableData: [],
            listLoading: false,
            reviewArea: 0,
            reviewQuota: 0,
            //关于分页的obj
            pages: {
                pageSize: 17,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: this.dataCount, //默认总页数
            },
            // 新建短信群发
            applyForSendSMSDialog: false,
            applyForSendSMLoading: false,
            applyForSendSMSForm: {
                name: '',
            },
            applyForSendSMSRules:{
                name:[{required:true,message:'请选择通知名称',trigger:'blur'}],
            },
            pickerData: [],
          exportList:[],
          exportBoolean:false,
          exportName:[
            {header:'项目编号',property:'projectNo'},
            {header: '项目名称',property: 'projectName'},
            {header: '县（区）',property: 'areaName'},
            {header: '关联合同编号',property: 'contractBn'},
            {header: '项目联系人',property: 'epName'},
            {header: '联系方式',property: 'epPhone'},
            {header: '合同单价（元）',property: 'projectUnitPrice'},
            {header: '图审面积（平方米）',property: 'projectArea'},
            {header: '项目金额（元）',property: 'projectTotalPrice'},
            {header: '项目状态',property: 'conclusionStatus'},
            {header: '项目归档日期',property: 'conclusionDateTime'},
            {header: '收款匹配状态（同右）',property: 'verificationStatus'},
            {header: '发票匹配状态（0:未匹配，1：部分匹配，2：全匹配）',property: 'invoiceStatus'},
          ],
          isExporting:false,
        }
    },
    methods: {
        callFunction(item) {
            this[item.func].apply(this, item);
        },
        // 列表查询接口
        getDataList(){
            let params = {
              provinceCode:this.filters?this.filters.provinceCode:undefined,//省
              conclusionStatus: this.filters ? this.filters.conclusionStatus : undefined,//审结状态
              filingStartDate: this.filters ? this.filters.filingStartDate : undefined,//归档开始日期
              filingEndDate: this.filters ? this.filters.filingEndDate: undefined,//归档结束日期
              invoiceStatus: this.filters ? this.filters.invoiceMatch : undefined,//匹配开票状态
              verificationStatus: this.filters ? this.filters.projectMatch : undefined,//匹配收款状态
              pageSize:this.pages.pageSize,//每页数量
              pageIndex:this.pages.pageIndex,//页码
            }
            getProjectConclusionReportList(params).then(res => {
                var result = res.data
                if(result.success) {
                    this.dataCount = result.response.dataCount
                    this.tableData = result.response.data
                }
            })
        },
      //导出汇总
      async exportAll(){
        for (let i = 1; i < ((this.dataCount / this.pages.pageSize) < 1 ? 2 : +Math.ceil(this.dataCount / this.pages.pageSize)) + 1; i++) {
          let params = {
              provinceCode: this.filters ? this.filters.provinceCode : undefined,//省
              conclusionStatus: this.filters ? this.filters.conclusionStatus : undefined,//审结状态
              filingStartDate: this.filters ? this.filters.filingStartDate : undefined,//归档开始日期
              filingEndDate: this.filters ? this.filters.filingEndDate : undefined,//归档结束日期
              invoiceStatus: this.filters ? this.filters.invoiceMatch : undefined,//匹配开票状态
              verificationStatus: this.filters ? this.filters.projectMatch : undefined,//匹配收款状态
              pageSize: 17,//每页数量
              pageIndex: i,//页码
            }
            const res = await getProjectConclusionReportList(params)
              var result = res.data
              if (result.success) {
               this.exportList.push(...result.response.data)
              }
            setTimeout(()=>{

            },100)
          }
        console.log(this.exportList,'exportList')
      },
        // 查询
        getListData() {
            this.pages.pageIndex = 1
            this.getDataList();
        },
      exportToExcel (dataArray, headersMapping) {
        // 从映射中提取自定义表头
        const headers = headersMapping.map(mapping => mapping.header);

        // 从数据中筛选并映射对应属性
        const filteredData = dataArray.map(obj =>
            headersMapping.reduce((acc, mapping) => {
              const { header, property } = mapping;
              if (Object.prototype.hasOwnProperty.call(obj, property)) {
                acc[header] = obj[property];
              }
              return acc;
            }, {})
        );

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(filteredData, { header: headers });

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        const excelBuffer = XLSX.write(workbook, {
          bookType: 'xlsx',
          type: 'array',
        });

        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileName = '项目收款匹配.xlsx';

        if (window.navigator.msSaveOrOpenBlob) {
          // For IE/Edge browsers
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          // For other browsers
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      },
      //导出
      async exportListData () {

        if (this.isExporting) {
          console.log('导出操作仍在进行中...');
          this.$message.error('导出操作仍在进行中...')
          return; // 如果导出仍在进行中，则退出函数
        }
        this.isExporting = true; //（isExporting是一个用于控制按钮状态的变量）
        await this.exportAll()
         setTimeout(()=>{
           this.exportToExcel(this.exportList,this.exportName)
           this.isExporting = false;
           this.exportList = []
         },0)

      },
        getStData() {
            var params = {}
            if(this.filters.provinceCode){
                params.provinceCode = this.filters.provinceCode;
            }
            if(this.filters.conclusionStatus){
                params.conclusionStatus = this.filters.conclusionStatus;
            }
            if(this.filters.filingStartDate){
                params.filingStartDate = this.filters.filingStartDate;
            }
            if(this.filters.filingEndDate){
                params.filingEndDate = this.filters.filingEndDate;
            }
            getProjectConclusionSummary(params).then(res => {
                var result = res.data
                if(result.success) {
                    this.reviewArea = result.response.totalProjectArea
                    this.reviewQuota = result.response.totalProjectPrice
                }
            })
        },
        // 新建短信群发
        applyForSendSMS() {
            this.applyForSendSMSDialog = true
            this.applyForSendSMLoading = false
        },
        // 新建短信群发提交
        applyForSendSMSubmit() {

        },
        // 审核通过
        approved() {

        },
        // 审核驳回
        reviewFailed() {

        },
        // 点击table某一行
        currentChange(val) {
            this.needData = val
        },
        // 分页
        handleCurrentChange(val) {
          console.log(val,'val')
            this.pages.pageIndex = val;
            this.getDataList();
        },
        handleSizeChange(val){
            this.pages.pageSize = val;
            this.getDataList();
        },
        // 时间格式化
        formatCreateTime: function(row, column) {
            return !row.NoticeTime || row.NoticeTime == ""
            ? ""
            : util.formatDate.format(new Date(row.NoticeTime), "yyyy-MM-dd hh:mm");
        },
        async init(){
            //获取所有省份
            const initResult = await Promise.all([
                getAreaDictionaryList({ parentId: 1 }),
            ])
            const [provinceRes] = initResult;
            if (provinceRes.data.success) {
                this.provinceArr = provinceRes.data.response.reverse();
            }
            this.getDataList();
            this.getStData();
        },
        // 日期选择
        changePicker(data) {
            if (data !== null) {
                this.filters.filingStartDate = data[0];
                this.filters.filingEndDate = data[1];
            } else {
                this.filters.filingStartDate = undefined;
                this.filters.filingEndDate = undefined;
            }
        },
        rowClass({columnIndex}){
            return 'padding:8px!important;'
        },
        // 表格数据分页条数累计
        indexMethod(index) {
            var currentPage = this.pages.pageIndex
            var pageSize = this.pages.pageSize
            return (index + 1) + (currentPage - 1) * pageSize
        },
    },
    watch: {
    },
    created(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
        this.init();
    }
}
</script>

<style lang="stylus" scoped>

</style>
